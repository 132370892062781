import http from '@/utils/http'
import qs from 'qs'
export const companyassesscompanylist = (params) =>{
    return http.get('/company/assesscompanylist',params)
}
export const companyassesscompanycitylist = (params) =>{
    return http.get('/company/assesscompanycitylist',params)
}
export const companygetcompanyinfo = (params) =>{
    return http.get('/company/getcompanyinfo',params)
}
export const companygetbusinesschannel = (params) =>{
    return http.get('/company/getbusinesschannel',params)
}
export const getcompanyquerylist = (params) =>{
    return http.get('/company/getcompanyquerylist',params)
}
export const getcompanycate = (params) =>{
    return http.get('/company/getcompanycate',params)
}
export const allcompanylist = (params) =>{
    return http.get('/company/allcompanylist',params)
}
export const getnewlist = (params) =>{
    return http.post('/sysnews/getnewlist',params)
}
export const getnewdetail = (params) =>{
    return http.get('/sysnews/getnewdetail',params)
}
export const getcompanyteamhonorlist = (params) =>{
    return http.get('/company/getcompanyteamhonorlist',params)
}