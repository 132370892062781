export const cityjson = [{
    "label": "北京",
    "value": "110000",
    "center": "116.405285,39.904989",
    "children": [{
        "label": "北京市",
        "value": "110100",
        "center": "116.405285,39.904989",
    }]
}, {
    "label": "天津",
    "value": "120000",
    "center": "117.190182,39.125596",
    "children": [{
        "label": "天津市",
        "value": "120100",
        "center": "117.190182,39.125596",
    }]
}, {
    "label": "河北省",
    "value": "130000",
    "center": "114.502461,38.045474",
    "children": [{
        "label": "石家庄市",
        "value": "130100",
        "center": "114.502461,38.045474",
    }, {
        "label": "衡水市",
        "value": "131100",
        "center": "115.665993,37.735097",
    }, {
        "label": "邢台市",
        "value": "130500",
        "center": "114.508851,37.0682",
    }, {
        "label": "保定市",
        "value": "130600",
        "center": "115.482331,38.867657",
    }, {
        "label": "张家口市",
        "value": "130700",
        "center": "114.884091,40.811901",
    }, {
        "label": "承德市",
        "value": "130800",
        "center": "117.939152,40.976204",
    }, {
        "label": "沧州市",
        "value": "130900",
        "center": "116.857461,38.310582",
    }, {
        "label": "廊坊市",
        "value": "131000",
        "center": "116.704441,39.523927",
    }, {
        "label": "邯郸市",
        "value": "130400",
        "center": "114.490686,36.612273",
    }, {
        "label": "秦皇岛市",
        "value": "130300",
        "center": "119.586579,39.942531",
    }, {
        "label": "唐山市",
        "value": "130200",
        "center": "118.175393,39.635113",
    }]
}, {
    "label": "山西省",
    "value": "140000",
    "center": "112.549248,37.857014",
    "children": [{
        "label": "太原市",
        "value": "140100",
        "center": "112.549248,37.857014",
    }, {
        "label": "大同市",
        "value": "140200",
        "center": "113.295259,40.09031",
    }, {
        "label": "阳泉市",
        "value": "140300",
        "center": "113.583285,37.861188",
    }, {
        "label": "长治市",
        "value": "140400",
        "center": "113.113556,36.191112",
    }, {
        "label": "晋城市",
        "value": "140500",
        "center": "112.851274,35.497553",
    }, {
        "label": "朔州市",
        "value": "140600",
        "center": "112.433387,39.331261",
    }, {
        "label": "晋中市",
        "value": "140700",
        "center": "112.736465,37.696495",
    }, {
        "label": "运城市",
        "value": "140800",
        "center": "111.003957,35.022778",
    }, {
        "label": "忻州市",
        "value": "140900",
        "center": "112.733538,38.41769",
    }, {
        "label": "临汾市",
        "value": "141000",
        "center": "111.517973,36.08415",
    }, {
        "label": "吕梁市",
        "value": "141100",
        "center": "111.134335,37.524366",
    }]
}, {
    "label": "内蒙古自治区",
    "value": "150000",
    "center": "111.670801,40.818311",
    "children": [{
        "label": "鄂尔多斯市",
        "value": "150600",
        "center": "109.99029,39.817179",
    }, {
        "label": "呼伦贝尔市",
        "value": "150700",
        "center": "119.758168,49.215333",
    }, {
        "label": "巴彦淖尔市",
        "value": "150800",
        "center": "107.416959,40.757402",
    }, {
        "label": "乌兰察布市",
        "value": "150900",
        "center": "113.114543,41.034126",
    }, {
        "label": "乌海市",
        "value": "150300",
        "center": "106.825563,39.673734",
    }, {
        "label": "赤峰市",
        "value": "150400",
        "center": "118.956806,42.275317",
    }, {
        "label": "呼和浩特市",
        "value": "150100",
        "center": "111.670801,40.818311",
    }, {
        "label": "包头市",
        "value": "150200",
        "center": "109.840405,40.658168",
    }, {
        "label": "通辽市",
        "value": "150500",
        "center": "122.263119,43.617429",
    }, {
        "label": "锡林郭勒盟",
        "value": "152500",
        "center": "116.090996,43.944018",
    }, {
        "label": "阿拉善盟",
        "value": "152900",
        "center": "105.706422,38.844814",
    }, {
        "label": "兴安盟",
        "value": "152200",
        "center": "122.070317,46.076268",
    }]
}, {
    "label": "广西壮族自治区",
    "value": "450000",
    "center": "108.327546,22.815478",
    "children": [{
        "label": "南宁市",
        "value": "450100",
        "center": "108.320004,22.82402",
    }, {
        "label": "柳州市",
        "value": "450200",
        "center": "109.411703,24.314617",
    }, {
        "label": "桂林市",
        "value": "450300",
        "center": "110.299121,25.274215",
    }, {
        "label": "梧州市",
        "value": "450400",
        "center": "111.297604,23.474803",
    }, {
        "label": "北海市",
        "value": "450500",
        "center": "109.119254,21.473343",
    }, {
        "label": "防城港市",
        "value": "450600",
        "center": "108.345478,21.614631",
    }, {
        "label": "钦州市",
        "value": "450700",
        "center": "108.624175,21.967127",
    }, {
        "label": "贵港市",
        "value": "450800",
        "center": "109.602146,23.0936",
    }, {
        "label": "玉林市",
        "value": "450900",
        "center": "110.154393,22.63136",
    }, {
        "label": "百色市",
        "value": "451000",
        "center": "106.616285,23.897742",
    }, {
        "label": "贺州市",
        "value": "451100",
        "center": "111.552056,24.414141",
    }, {
        "label": "河池市",
        "value": "451200",
        "center": "108.062105,24.695899",
    }, {
        "label": "来宾市",
        "value": "451300",
        "center": "109.229772,23.733766",
    }, {
        "label": "崇左市",
        "value": "451400",
        "center": "107.353926,22.404108",
    }]
}, {
    "label": "福建省",
    "value": "350000",
    "center": "119.295143,26.100779",
    "children": [{
        "label": "福州市",
        "value": "350100",
        "center": "119.306239,26.075302",
    }, {
        "label": "厦门市",
        "value": "350200",
        "center": "118.11022,24.490474",
    }, {
        "label": "莆田市",
        "value": "350300",
        "center": "119.007558,25.431011",
    }, {
        "label": "三明市",
        "value": "350400",
        "center": "117.635001,26.265444",
    }, {
        "label": "泉州市",
        "value": "350500",
        "center": "118.589421,24.908853",
    }, {
        "label": "漳州市",
        "value": "350600",
        "center": "117.661801,24.510897",
    }, {
        "label": "南平市",
        "value": "350700",
        "center": "118.178459,26.635627",
    }, {
        "label": "龙岩市",
        "value": "350800",
        "center": "117.02978,25.091603",
    }, {
        "label": "宁德市",
        "value": "350900",
        "center": "119.527082,26.65924",
    }]
}, {
    "label": "江西省",
    "value": "360000",
    "center": "115.892151,28.676493",
    "children": [{
        "label": "南昌市",
        "value": "360100",
        "center": "115.892151,28.676493",
    }, {
        "label": "景德镇市",
        "value": "360200",
        "center": "117.214664,29.29256",
    }, {
        "label": "萍乡市",
        "value": "360300",
        "center": "113.852186,27.622946",
    }, {
        "label": "九江市",
        "value": "360400",
        "center": "115.992811,29.712034",
    }, {
        "label": "新余市",
        "value": "360500",
        "center": "114.930835,27.810834",
    }, {
        "label": "鹰潭市",
        "value": "360600",
        "center": "117.033838,28.238638",
    }, {
        "label": "赣州市",
        "value": "360700",
        "center": "114.940278,25.85097",
    }, {
        "label": "吉安市",
        "value": "360800",
        "center": "114.986373,27.111699",
    }, {
        "label": "宜春市",
        "value": "360900",
        "center": "114.391136,27.8043",
    }, {
        "label": "抚州市",
        "value": "361000",
        "center": "116.358351,27.98385",
    }, {
        "label": "上饶市",
        "value": "361100",
        "center": "117.971185,28.44442",
    }]
}, {
    "label": "山东省",
    "value": "370000",
    "center": "117.000923,36.675807",
    "children": [{
        "label": "济南市",
        "value": "370100",
        "center": "117.000923,36.675807",
    }, {
        "label": "青岛市",
        "value": "370200",
        "center": "120.355173,36.082982",
    }, {
        "label": "淄博市",
        "value": "370300",
        "center": "118.047648,36.814939",
    }, {
        "label": "枣庄市",
        "value": "370400",
        "center": "117.557964,34.856424",
    }, {
        "label": "东营市",
        "value": "370500",
        "center": "118.66471,37.434564",
    }, {
        "label": "烟台市",
        "value": "370600",
        "center": "121.391382,37.539297",
    }, {
        "label": "潍坊市",
        "value": "370700",
        "center": "119.107078,36.70925",
    }, {
        "label": "济宁市",
        "value": "370800",
        "center": "116.587245,35.415393",
    }, {
        "label": "泰安市",
        "value": "370900",
        "center": "117.129063,36.194968",
    }, {
        "label": "威海市",
        "value": "371000",
        "center": "122.116394,37.509691",
    }, {
        "label": "日照市",
        "value": "371100",
        "center": "119.461208,35.428588",
    }, {
        "label": "莱芜市",
        "value": "371200",
        "center": "117.677736,36.214397",
    }, {
        "label": "临沂市",
        "value": "371300",
        "center": "118.326443,35.065282",
    }, {
        "label": "德州市",
        "value": "371400",
        "center": "116.307428,37.453968",
    }, {
        "label": "聊城市",
        "value": "371500",
        "center": "115.980367,36.456013",
    }, {
        "label": "滨州市",
        "value": "371600",
        "center": "118.016974,37.383542",
    }, {
        "label": "菏泽市",
        "value": "371700",
        "center": "115.469381,35.246531",
    }]
}, {
    "label": "河南省",
    "value": "410000",
    "center": "113.665412,34.757975",
    "children": [{
        "label": "郑州市",
        "value": "410100",
        "center": "113.665412,34.757975",
    }, {
        "label": "开封市",
        "value": "410200",
        "center": "114.341447,34.797049",
    }, {
        "label": "洛阳市",
        "value": "410300",
        "center": "112.434468,34.663041",
    }, {
        "label": "平顶山市",
        "value": "410400",
        "center": "113.307718,33.735241",
    }, {
        "label": "安阳市",
        "value": "410500",
        "center": "114.352482,36.103442",
    }, {
        "label": "鹤壁市",
        "value": "410600",
        "center": "114.295444,35.748236",
    }, {
        "label": "新乡市",
        "value": "410700",
        "center": "113.883991,35.302616",
    }, {
        "label": "焦作市",
        "value": "410800",
        "center": "113.238266,35.23904",
    }, {
        "label": "濮阳市",
        "value": "410900",
        "center": "115.041299,35.768234",
    }, {
        "label": "许昌市",
        "value": "411000",
        "center": "113.826063,34.022956",
    }, {
        "label": "漯河市",
        "value": "411100",
        "center": "114.026405,33.575855",
    }, {
        "label": "三门峡市",
        "value": "411200",
        "center": "111.194099,34.777338",
    }, {
        "label": "南阳市",
        "value": "411300",
        "center": "112.540918,32.999082",
    }, {
        "label": "商丘市",
        "value": "411400",
        "center": "115.650497,34.437054",
    }, {
        "label": "信阳市",
        "value": "411500",
        "center": "114.075031,32.123274",
    }, {
        "label": "周口市",
        "value": "411600",
        "center": "114.649653,33.620357",
    }, {
        "label": "驻马店市",
        "value": "411700",
        "center": "114.024736,32.980169",
    }, {
        "label": "济源市",
        "value": "419001",
        "center": "112.509,35.0243",
    }]
}, {
    "label": "湖北省",
    "value": "420000",
    "center": "114.298572,30.584355",
    "children": [{
        "label": "武汉市",
        "value": "420100",
        "center": "114.298572,30.584355",
    }, {
        "label": "黄石市",
        "value": "420200",
        "center": "115.077048,30.220074",
    }, {
        "label": "十堰市",
        "value": "420300",
        "center": "110.787916,32.646907",
    }, {
        "label": "宜昌市",
        "value": "420500",
        "center": "111.290843,30.702636",
    }, {
        "label": "襄阳市",
        "value": "420600",
        "center": "112.144146,32.042426",
    }, {
        "label": "鄂州市",
        "value": "420700",
        "center": "114.890593,30.396536",
    }, {
        "label": "荆门市",
        "value": "420800",
        "center": "112.204251,31.03542",
    }, {
        "label": "孝感市",
        "value": "420900",
        "center": "113.926655,30.926423",
    }, {
        "label": "荆州市",
        "value": "421000",
        "center": "112.23813,30.326857",
    }, {
        "label": "黄冈市",
        "value": "421100",
        "center": "114.879365,30.447711",
    }, {
        "label": "咸宁市",
        "value": "421200",
        "center": "114.328963,29.832798",
    }, {
        "label": "随州市",
        "value": "421300",
        "center": "113.37377,31.717497",
    }, {
        "label": "恩施土家族苗族自治州",
        "value": "422800",
        "center": "109.48699,30.283114",
    }, {
        "label": "省直辖",
        "value": "429000",
        "center": "114.351688,30.546452",
    }]
}, {
    "label": "海南省",
    "value": "460000",
    "center": "110.33119,20.031971",
    "children": [{
        "label": "海口市",
        "value": "460100",
        "center": "110.33119,20.031971",
    }, {
        "label": "三亚市",
        "value": "460200",
        "center": "109.508268,18.247872",
    }, {
        "label": "三沙市",
        "value": "460300",
        "center": "112.34882,16.831039",
    }, {
        "label": "省直辖",
        "value": "469000",
        "center": "110.33119,20.031971",
    }]
}, {
    "label": "重庆",
    "value": "500000",
    "center": "106.504962,29.533155",
    "children": [{
        "label": "重庆市",
        "value": "500100",
        "center": "106.504962,29.533155",
    }]
}, {
    "label": "四川省",
    "value": "510000",
    "center": "104.065735,30.659462",
    "children": [{
        "label": "成都市",
        "value": "510100",
        "center": "104.065735,30.659462",
    }, {
        "label": "自贡市",
        "value": "510300",
        "center": "104.773447,29.352765",
    }, {
        "label": "攀枝花市",
        "value": "510400",
        "center": "101.716007,26.580446",
    }, {
        "label": "泸州市",
        "value": "510500",
        "center": "105.443348,28.889138",
    }, {
        "label": "德阳市",
        "value": "510600",
        "center": "104.398651,31.127991",
    }, {
        "label": "绵阳市",
        "value": "510700",
        "center": "104.741722,31.46402",
    }, {
        "label": "广元市",
        "value": "510800",
        "center": "105.829757,32.433668",
    }, {
        "label": "遂宁市",
        "value": "510900",
        "center": "105.571331,30.513311",
    }, {
        "label": "内江市",
        "value": "511000",
        "center": "105.066138,29.58708",
    }, {
        "label": "乐山市",
        "value": "511100",
        "center": "103.761263,29.582024",
    }, {
        "label": "南充市",
        "value": "511300",
        "center": "106.082974,30.795281",
    }, {
        "label": "眉山市",
        "value": "511400",
        "center": "103.831788,30.048318",
    }, {
        "label": "宜宾市",
        "value": "511500",
        "center": "104.630825,28.760189",
    }, {
        "label": "广安市",
        "value": "511600",
        "center": "106.633369,30.456398",
    }, {
        "label": "达州市",
        "value": "511700",
        "center": "107.502262,31.209484",
    }, {
        "label": "雅安市",
        "value": "511800",
        "center": "103.001033,29.987722",
    }, {
        "label": "巴中市",
        "value": "511900",
        "center": "106.753669,31.858809",
    }, {
        "label": "资阳市",
        "value": "512000",
        "center": "104.641917,30.122211",
    }, {
        "label": "阿坝藏族羌族自治州",
        "value": "513200",
        "center": "102.221374,31.899792",
    }, {
        "label": "甘孜藏族自治州",
        "value": "513300",
        "center": "101.963815,30.050663",
    }, {
        "label": "凉山彝族自治州",
        "value": "513400",
        "center": "102.258746,27.886762",
    }]
}, {
    "label": "贵州省",
    "value": "520000",
    "center": "106.713478,26.578343",
    "children": [{
        "label": "贵阳市",
        "value": "520100",
        "center": "106.713478,26.578343",
    }, {
        "label": "六盘水市",
        "value": "520200",
        "center": "104.846743,26.584643",
    }, {
        "label": "遵义市",
        "value": "520300",
        "center": "106.937265,27.706626",
    }, {
        "label": "安顺市",
        "value": "520400",
        "center": "105.932188,26.245544",
    }, {
        "label": "毕节市",
        "value": "520500",
        "center": "105.28501,27.301693",
    }, {
        "label": "铜仁市",
        "value": "520600",
        "center": "109.191555,27.718346",
    }, {
        "label": "黔西南布依族苗族自治州",
        "value": "522300",
        "center": "104.897971,25.08812",
    }, {
        "label": "黔东南苗族侗族自治州",
        "value": "522600",
        "center": "107.977488,26.583352",
    }, {
        "label": "黔南布依族苗族自治州",
        "value": "522700",
        "center": "107.517156,26.258219",
    }]
}, {
    "label": "云南省",
    "value": "530000",
    "center": "102.712251,25.040609",
    "children": [{
        "label": "昆明市",
        "value": "530100",
        "center": "102.712251,25.040609",
    }, {
        "label": "曲靖市",
        "value": "530300",
        "center": "103.797851,25.501557",
    }, {
        "label": "玉溪市",
        "value": "530400",
        "center": "102.543907,24.350461",
    }, {
        "label": "保山市",
        "value": "530500",
        "center": "99.167133,25.111802",
    }, {
        "label": "昭通市",
        "value": "530600",
        "center": "103.717216,27.336999",
    }, {
        "label": "丽江市",
        "value": "530700",
        "center": "100.233026,26.872108",
    }, {
        "label": "普洱市",
        "value": "530800",
        "center": "100.972344,22.777321",
    }, {
        "label": "临沧市",
        "value": "530900",
        "center": "100.08697,23.886567",
    }, {
        "label": "楚雄彝族自治州",
        "value": "532300",
        "center": "101.546046,25.041988",
    }, {
        "label": "红河哈尼族彝族自治州",
        "value": "532500",
        "center": "103.384182,23.366775",
    }, {
        "label": "文山壮族苗族自治州",
        "value": "532600",
        "center": "104.24401,23.36951",
    }, {
        "label": "西双版纳傣族自治州",
        "value": "532800",
        "center": "100.797941,22.001724",
    }, {
        "label": "大理白族自治州",
        "value": "532900",
        "center": "100.225668,25.589449",
    }, {
        "label": "德宏傣族景颇族自治州",
        "value": "533100",
        "center": "98.578363,24.436694",
    }, {
        "label": "怒江傈僳族自治州",
        "value": "533300",
        "center": "98.854304,25.850949",
    }, {
        "label": "迪庆藏族自治州",
        "value": "533400",
        "center": "99.706463,27.826853",
    }]
}, {
    "label": "西藏自治区",
    "value": "540000",
    "center": "91.117525,29.647535",
    "children": [{
        "label": "拉萨市",
        "value": "540100",
        "center": "91.132212,29.660361",
    }, {
        "label": "昌都地区",
        "value": "542100",
        "center": "97.18131,31.140161",
    }, {
        "label": "山南地区",
        "value": "542200",
        "center": "91.773134,29.237137",
    }, {
        "label": "日喀则地区",
        "value": "542300",
        "center": "88.885817,29.26681",
    }, {
        "label": "那曲地区",
        "value": "542400",
        "center": "92.051746,31.478148",
    }, {
        "label": "阿里地区",
        "value": "542500",
        "center": "80.105498,32.503187",
    }, {
        "label": "林芝地区",
        "value": "542600",
        "center": "94.36149,29.649128",
    }]
}, {
    "label": "陕西省",
    "value": "610000",
    "center": "108.948024,34.263161",
    "children": [{
        "label": "西安市",
        "value": "610100",
        "center": "108.948024,34.263161",
    }, {
        "label": "铜川市",
        "value": "610200",
        "center": "108.979608,34.916582",
    }, {
        "label": "宝鸡市",
        "value": "610300",
        "center": "107.14487,34.369315",
    }, {
        "label": "咸阳市",
        "value": "610400",
        "center": "108.705117,34.333439",
    }, {
        "label": "渭南市",
        "value": "610500",
        "center": "109.502882,34.499381",
    }, {
        "label": "延安市",
        "value": "610600",
        "center": "109.49081,36.596537",
    }, {
        "label": "汉中市",
        "value": "610700",
        "center": "107.028621,33.077668",
    }, {
        "label": "榆林市",
        "value": "610800",
        "center": "109.741193,38.290162",
    }, {
        "label": "安康市",
        "value": "610900",
        "center": "109.029273,32.6903",
    }, {
        "label": "商洛市",
        "value": "611000",
        "center": "109.939776,33.868319",
    }]
}, {
    "label": "甘肃省",
    "value": "620000",
    "center": "103.826447,36.05956",
    "children": [{
        "label": "兰州市",
        "value": "620100",
        "center": "103.823557,36.058039",
    }, {
        "label": "嘉峪关市",
        "value": "620200",
        "center": "98.255,39.8552",
    }, {
        "label": "金昌市",
        "value": "620300",
        "center": "102.187888,38.514238",
    }, {
        "label": "白银市",
        "value": "620400",
        "center": "104.173606,36.54568",
    }, {
        "label": "天水市",
        "value": "620500",
        "center": "105.724998,34.578529",
    }, {
        "label": "武威市",
        "value": "620600",
        "center": "102.634697,37.929996",
    }, {
        "label": "张掖市",
        "value": "620700",
        "center": "100.455472,38.932897",
    }, {
        "label": "平凉市",
        "value": "620800",
        "center": "106.684691,35.54279",
    }, {
        "label": "酒泉市",
        "value": "620900",
        "center": "98.510795,39.744023",
    }, {
        "label": "庆阳市",
        "value": "621000",
        "center": "107.638372,35.734218",
    }, {
        "label": "定西市",
        "value": "621100",
        "center": "104.626294,35.579578",
    }, {
        "label": "陇南市",
        "value": "621200",
        "center": "104.929379,33.388598",
    }, {
        "label": "临夏回族自治州",
        "value": "622900",
        "center": "103.212006,35.599446",
    }, {
        "label": "甘南藏族自治州",
        "value": "623000",
        "center": "102.911008,34.986354",
    }]
}, {
    "label": "青海省",
    "value": "630000",
    "center": "101.778916,36.623178",
    "children": [{
        "label": "西宁市",
        "value": "630100",
        "center": "101.778916,36.623178",
    }, {
        "label": "海东地区",
        "value": "632100",
        "center": "102.106684,36.496876",
    }, {
        "label": "海北藏族自治州",
        "value": "632200",
        "center": "100.901059,36.959435",
    }, {
        "label": "黄南藏族自治州",
        "value": "632300",
        "center": "102.019988,35.517744",
    }, {
        "label": "海南藏族自治州",
        "value": "632500",
        "center": "100.619542,36.280353",
    }, {
        "label": "果洛藏族自治州",
        "value": "632600",
        "center": "100.242143,34.4736",
    }, {
        "label": "玉树藏族自治州",
        "value": "632700",
        "center": "97.008522,33.004049",
    }, {
        "label": "海西蒙古族藏族自治州",
        "value": "632800",
        "center": "97.370785,37.374663",
    }]
}, {
    "label": "宁夏回族自治区",
    "value": "640000",
    "center": "106.278179,38.46637",
    "children": [{
        "label": "银川市",
        "value": "640100",
        "center": "106.278179,38.46637",
    }, {
        "label": "石嘴山市",
        "value": "640200",
        "center": "106.376173,39.01333",
    }, {
        "label": "吴忠市",
        "value": "640300",
        "center": "106.199409,37.986165",
    }, {
        "label": "固原市",
        "value": "640400",
        "center": "106.285241,36.004561",
    }, {
        "label": "中卫市",
        "value": "640500",
        "center": "105.189568,37.514951",
    }]
}, {
    "label": "新疆维吾尔自治区",
    "value": "650000",
    "center": "87.627704,43.793026",
    "children": [{
        "label": "乌鲁木齐市",
        "value": "650100",
        "center": "87.617733,43.792818",
    }, {
        "label": "克拉玛依市",
        "value": "650200",
        "center": "84.873946,45.595886",
    }, {
        "label": "吐鲁番地区",
        "value": "652100",
        "center": "89.186661,42.941938",
    }, {
        "label": "哈密地区",
        "value": "652200",
        "center": "93.491679,42.918549",
    }, {
        "label": "昌吉回族自治州",
        "value": "652300",
        "center": "87.304012,44.014577",
    }, {
        "label": "博尔塔拉蒙古自治州",
        "value": "652700",
        "center": "82.074778,44.903258",
    }, {
        "label": "巴音郭楞蒙古自治州",
        "value": "652800",
        "center": "86.150969,41.768552",
    }, {
        "label": "阿克苏地区",
        "value": "652900",
        "center": "80.265068,41.170712",
    }, {
        "label": "克孜勒苏柯尔克孜自治州",
        "value": "653000",
        "center": "76.172825,39.713431",
    }, {
        "label": "喀什地区",
        "value": "653100",
        "center": "75.989138,39.467664",
    }, {
        "label": "和田地区",
        "value": "653200",
        "center": "79.92533,37.110687",
    }, {
        "label": "伊犁哈萨克自治州",
        "value": "654000",
        "center": "81.317946,43.92186",
    }, {
        "label": "塔城地区",
        "value": "654200",
        "center": "82.985732,46.746301",
    }, {
        "label": "阿勒泰地区",
        "value": "654300",
        "center": "88.13963,47.848393",
    }, {
        "label": "自治区直辖",
        "value": "659000",
        "center": "87.617733,43.792818",
    }]
}, {
    "label": "台湾省",
    "value": "710000",
    "center": "121.509062,25.044332",
    "children": [{
        "label": "台北市",
        "value": "710100",
        "center": "",
    }, {
        "label": "高雄市",
        "value": "710200",
        "center": "",
    }, {
        "label": "基隆市",
        "value": "710300",
        "center": "",
    }, {
        "label": "台中市",
        "value": "710400",
        "center": "",
    }, {
        "label": "台南市",
        "value": "710500",
        "center": "",
    }, {
        "label": "新竹市",
        "value": "710600",
        "center": "",
    }, {
        "label": "嘉义市",
        "value": "710700",
        "center": "",
    }, {
        "label": "省直辖",
        "value": "719000",
        "center": "",
    }]
}, {
    "label": "香港特别行政区",
    "value": "810000",
    "center": "114.173355,22.320048",
    "children": [{
        "label": "香港岛",
        "value": "810100",
        "center": "",
    }, {
        "label": "九龙",
        "value": "810200",
        "center": "",
    }, {
        "label": "新界",
        "value": "810300",
        "center": "",
    }]
}, {
    "label": "澳门特别行政区",
    "value": "820000",
    "center": "113.54909,22.198951",
    "children": [{
        "label": "澳门半岛",
        "value": "820100",
        "center": "",
    }, {
        "label": "澳门离岛",
        "value": "820200",
        "center": "",
    }, {
        "label": "无堂区划分区域",
        "value": "820300",
        "center": "",
    }]
}, {
    "label": "湖南省",
    "value": "430000",
    "center": "112.982279,28.19409",
    "children": [{
        "label": "邵阳市",
        "value": "430500",
        "center": "111.46923,27.237842",
    }, {
        "label": "常德市",
        "value": "430700",
        "center": "111.691347,29.040225",
    }, {
        "label": "张家界市",
        "value": "430800",
        "center": "110.479921,29.127401",
    }, {
        "label": "益阳市",
        "value": "430900",
        "center": "112.355042,28.570066",
    }, {
        "label": "长沙市",
        "value": "430100",
        "center": "112.982279,28.19409",
    }, {
        "label": "郴州市",
        "value": "431000",
        "center": "113.032067,25.793589",
    }, {
        "label": "永州市",
        "value": "431100",
        "center": "111.608019,26.434516",
    }, {
        "label": "怀化市",
        "value": "431200",
        "center": "109.97824,27.550082",
    }, {
        "label": "娄底市",
        "value": "431300",
        "center": "112.008497,27.728136",
    }, {
        "label": "湘西土家族苗族自治州",
        "value": "433100",
        "center": "109.739735,28.314296",
    }, {
        "label": "株洲市",
        "value": "430200",
        "center": "113.151737,27.835806",
    }, {
        "label": "湘潭市",
        "value": "430300",
        "center": "112.944052,27.82973",
    }, {
        "label": "衡阳市",
        "value": "430400",
        "center": "112.607693,26.900358",
    }, {
        "label": "岳阳市",
        "value": "430600",
        "center": "113.132855,29.37029",
    }]
}, {
    "label": "广东省",
    "value": "440000",
    "center": "113.280637,23.125178",
    "children": [{
        "label": "韶关市",
        "value": "440200",
        "center": "113.591544,24.801322",
    }, {
        "label": "河源市",
        "value": "441600",
        "center": "114.697802,23.746266",
    }, {
        "label": "阳江市",
        "value": "441700",
        "center": "111.975107,21.859222",
    }, {
        "label": "清远市",
        "value": "441800",
        "center": "113.051227,23.685022",
    }, {
        "label": "东莞市",
        "value": "441900",
        "center": "113.846,23.1024",
    }, {
        "label": "中山市",
        "value": "442000",
        "center": "113.308,22.6803",
    }, {
        "label": "潮州市",
        "value": "445100",
        "center": "116.632301,23.661701",
    }, {
        "label": "揭阳市",
        "value": "445200",
        "center": "116.355733,23.543778",
    }, {
        "label": "云浮市",
        "value": "445300",
        "center": "112.044439,22.929801",
    }, {
        "label": "深圳市",
        "value": "440300",
        "center": "114.085947,22.547",
    }, {
        "label": "珠海市",
        "value": "440400",
        "center": "113.553986,22.224979",
    }, {
        "label": "汕头市",
        "value": "440500",
        "center": "116.708463,23.37102",
    }, {
        "label": "佛山市",
        "value": "440600",
        "center": "113.122717,23.028762",
    }, {
        "label": "江门市",
        "value": "440700",
        "center": "113.094942,22.590431",
    }, {
        "label": "湛江市",
        "value": "440800",
        "center": "110.364977,21.274898",
    }, {
        "label": "茂名市",
        "value": "440900",
        "center": "110.919229,21.659751",
    }, {
        "label": "肇庆市",
        "value": "441200",
        "center": "112.472529,23.051546",
    }, {
        "label": "惠州市",
        "value": "441300",
        "center": "114.412599,23.079404",
    }, {
        "label": "梅州市",
        "value": "441400",
        "center": "116.117582,24.299112",
    }, {
        "label": "汕尾市",
        "value": "441500",
        "center": "115.364238,22.774485",
    }, {
        "label": "广州市",
        "value": "440100",
        "center": "113.280637,23.125178",
    }]
}, {
    "label": "浙江省",
    "value": "330000",
    "center": "120.153576,30.287459",
    "children": [{
        "label": "绍兴市",
        "value": "330600",
        "center": "120.582112,29.997117",
    }, {
        "label": "金华市",
        "value": "330700",
        "center": "119.649506,29.089524",
    }, {
        "label": "衢州市",
        "value": "330800",
        "center": "118.87263,28.941708",
    }, {
        "label": "舟山市",
        "value": "330900",
        "center": "122.106863,30.016028",
    }, {
        "label": "台州市",
        "value": "331000",
        "center": "121.428599,28.661378",
    }, {
        "label": "丽水市",
        "value": "331100",
        "center": "119.921786,28.451993",
    }, {
        "label": "杭州市",
        "value": "330100",
        "center": "120.153576,30.287459",
    }, {
        "label": "宁波市",
        "value": "330200",
        "center": "121.549792,29.868388",
    }, {
        "label": "温州市",
        "value": "330300",
        "center": "120.672111,28.000575",
    }, {
        "label": "嘉兴市",
        "value": "330400",
        "center": "120.750865,30.762653",
    }, {
        "label": "湖州市",
        "value": "330500",
        "center": "120.102398,30.867198",
    }]
}, {
    "label": "安徽省",
    "value": "340000",
    "center": "117.283042,31.86119",
    "children": [{
        "label": "淮北市",
        "value": "340600",
        "center": "116.794664,33.971707",
    }, {
        "label": "铜陵市",
        "value": "340700",
        "center": "117.816576,30.929935",
    }, {
        "label": "安庆市",
        "value": "340800",
        "center": "117.043551,30.50883",
    }, {
        "label": "黄山市",
        "value": "341000",
        "center": "118.317325,29.709239",
    }, {
        "label": "滁州市",
        "value": "341100",
        "center": "118.316264,32.303627",
    }, {
        "label": "阜阳市",
        "value": "341200",
        "center": "115.819729,32.896969",
    }, {
        "label": "宿州市",
        "value": "341300",
        "center": "116.984084,33.633891",
    }, {
        "label": "六安市",
        "value": "341500",
        "center": "116.507676,31.752889",
    }, {
        "label": "亳州市",
        "value": "341600",
        "center": "115.782939,33.869338",
    }, {
        "label": "池州市",
        "value": "341700",
        "center": "117.489157,30.656037",
    }, {
        "label": "宣城市",
        "value": "341800",
        "center": "118.757995,30.945667",
    }, {
        "label": "合肥市",
        "value": "340100",
        "center": "117.283042,31.86119",
    }, {
        "label": "芜湖市",
        "value": "340200",
        "center": "118.376451,31.326319",
    }, {
        "label": "蚌埠市",
        "value": "340300",
        "center": "117.363228,32.939667",
    }, {
        "label": "淮南市",
        "value": "340400",
        "center": "117.018329,32.647574",
    }, {
        "label": "马鞍山市",
        "value": "340500",
        "center": "118.507906,31.689362",
    }]
}, {
    "label": "上海",
    "value": "310000",
    "center": "121.472644,31.231706",
    "children": [{
        "label": "上海市",
        "value": "310100",
        "center": "121.472644,31.231706",
    }]
}, {
    "label": "江苏省",
    "value": "320000",
    "center": "118.767413,32.041544",
    "children": [{
        "label": "无锡市",
        "value": "320200",
        "center": "120.301663,31.574729",
    }, {
        "label": "盐城市",
        "value": "320900",
        "center": "120.139998,33.377631",
    }, {
        "label": "扬州市",
        "value": "321000",
        "center": "119.421003,32.393159",
    }, {
        "label": "镇江市",
        "value": "321100",
        "center": "119.452753,32.204402",
    }, {
        "label": "泰州市",
        "value": "321200",
        "center": "119.915176,32.484882",
    }, {
        "label": "宿迁市",
        "value": "321300",
        "center": "118.275162,33.963008",
    }, {
        "label": "南京市",
        "value": "320100",
        "center": "118.767413,32.041544",
    }, {
        "label": "徐州市",
        "value": "320300",
        "center": "117.184811,34.261792",
    }, {
        "label": "常州市",
        "value": "320400",
        "center": "119.946973,31.772752",
    }, {
        "label": "苏州市",
        "value": "320500",
        "center": "120.619585,31.299379",
    }, {
        "label": "南通市",
        "value": "320600",
        "center": "120.864608,32.016212",
    }, {
        "label": "连云港市",
        "value": "320700",
        "center": "119.178821,34.600018",
    }, {
        "label": "淮安市",
        "value": "320800",
        "center": "119.021265,33.597506",
    }]
}, {
    "label": "吉林省",
    "value": "220000",
    "center": "125.3245,43.886841",
    "children": [{
        "label": "长春市",
        "value": "220100",
        "center": "125.3245,43.886841",
    }, {
        "label": "吉林市",
        "value": "220200",
        "center": "126.55302,43.843577",
    }, {
        "label": "四平市",
        "value": "220300",
        "center": "124.370785,43.170344",
    }, {
        "label": "辽源市",
        "value": "220400",
        "center": "125.145349,42.902692",
    }, {
        "label": "通化市",
        "value": "220500",
        "center": "125.936501,41.721177",
    }, {
        "label": "白山市",
        "value": "220600",
        "center": "126.427839,41.942505",
    }, {
        "label": "松原市",
        "value": "220700",
        "center": "124.823608,45.118243",
    }, {
        "label": "白城市",
        "value": "220800",
        "center": "122.841114,45.619026",
    }, {
        "label": "延边朝鲜族自治州",
        "value": "222400",
        "center": "129.513228,42.904823",
    }]
}, {
    "label": "辽宁省",
    "value": "210000",
    "center": "123.429096,41.796767",
    "children": [{
        "label": "阜新市",
        "value": "210900",
        "center": "121.648962,42.011796",
    }, {
        "label": "辽阳市",
        "value": "211000",
        "center": "123.18152,41.269402",
    }, {
        "label": "盘锦市",
        "value": "211100",
        "center": "122.06957,41.124484",
    }, {
        "label": "铁岭市",
        "value": "211200",
        "center": "123.844279,42.290585",
    }, {
        "label": "朝阳市",
        "value": "211300",
        "center": "120.451176,41.576758",
    }, {
        "label": "葫芦岛市",
        "value": "211400",
        "center": "120.856394,40.755572",
    }, {
        "label": "沈阳市",
        "value": "210100",
        "center": "123.429096,41.796767",
    }, {
        "label": "大连市",
        "value": "210200",
        "center": "121.618622,38.91459",
    }, {
        "label": "鞍山市",
        "value": "210300",
        "center": "122.995632,41.110626",
    }, {
        "label": "抚顺市",
        "value": "210400",
        "center": "123.921109,41.875956",
    }, {
        "label": "本溪市",
        "value": "210500",
        "center": "123.770519,41.297909",
    }, {
        "label": "丹东市",
        "value": "210600",
        "center": "124.383044,40.124296",
    }, {
        "label": "锦州市",
        "value": "210700",
        "center": "121.135742,41.119269",
    }, {
        "label": "营口市",
        "value": "210800",
        "center": "122.235151,40.667432",
    }]
}, {
    "label": "黑龙江省",
    "value": "230000",
    "center": "126.642464,45.756967",
    "children": [{
        "label": "大庆市",
        "value": "230600",
        "center": "125.11272,46.590734",
    }, {
        "label": "伊春市",
        "value": "230700",
        "center": "128.899396,47.724775",
    }, {
        "label": "佳木斯市",
        "value": "230800",
        "center": "130.361634,46.809606",
    }, {
        "label": "七台河市",
        "value": "230900",
        "center": "131.015584,45.771266",
    }, {
        "label": "牡丹江市",
        "value": "231000",
        "center": "129.618602,44.582962",
    }, {
        "label": "黑河市",
        "value": "231100",
        "center": "127.499023,50.249585",
    }, {
        "label": "绥化市",
        "value": "231200",
        "center": "126.99293,46.637393",
    }, {
        "label": "大兴安岭地区",
        "value": "232700",
        "center": "124.711526,52.335262",
    }, {
        "label": "哈尔滨市",
        "value": "230100",
        "center": "126.642464,45.756967",
    }, {
        "label": "齐齐哈尔市",
        "value": "230200",
        "center": "123.95792,47.342081",
    }, {
        "label": "鸡西市",
        "value": "230300",
        "center": "130.975966,45.300046",
    }, {
        "label": "鹤岗市",
        "value": "230400",
        "center": "130.277487,47.332085",
    }, {
        "label": "双鸭山市",
        "value": "230500",
        "center": "131.157304,46.643442",
    }]
}]